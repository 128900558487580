<template>
  <div
    class="ts-addon-card ts-shadow-3"
    :class="popular ? 'ts-addon-card--active' : ''"
  >
    <div class="special-offer deg-45" v-if="popular">
      <div class="offer-discount">
        <div class="offer-discount-rotate rotate-45">
          <strong
            class="text-white fw-medium"
            v-if="$store.state.subscriptionPage"
          >
            {{ $store.state.subscriptionPage.popularCardTag }}</strong
          >
        </div>
      </div>
    </div>

    <h5 class="ts-pricing-card__title fw-bold mb-05">{{ title }}</h5>
    <p class="ts-pricing-card__subtitle mb-06">
      {{ description }}
    </p>
    <div>
      <p class="mb-0">{{ startat }}</p>
      <h5 class="ts-fs-4 ts-pricing-card__pricing-price fw-bold mb-0">
        <span class="ts-pricing-card__pricing-currency">{{ currency }}</span>
        {{ price
        }}<span class="ts-pricing-card__pricing-month fw-normal"
          >/{{ month }}</span
        >
      </h5>
      <p class="mb-05">
        {{ priceDetails }}
      </p>
    </div>
    <router-link
      class="btn ts-btn ts-btn-primary rounded-pill w-100 fw-bold mb-07"
      :to="'/' + BtnLink"
    >
      {{ Btntext }}
    </router-link>
    <ul
      class="ts-pricing-card__list list-unstyled d-flex flex-column ts-gap-20 mb-0"
    >
      <li
        v-for="(item, index) in featureList"
        :key="index"
        class="d-flex align-items-center gap-3"
      >
        <Icon name="check-circle-filled" />
        <span>{{ item.Item }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import Icon from "@/assets/icons/index.vue";
export default {
  components: {
    Icon,
  },

  props: {
    popular: Boolean,
    title: String,
    description: String,
    startat: String,
    currency: String,
    price: String,
    month: String,
    priceDetails: String,
    Btntext: String,
    BtnLink: String,
    featureList: Array,
  },
};
</script>

<style lang="scss" scoped>
.ts-addon-card {
  padding: clamp(2.375rem, 4.5vw, 3rem) clamp(1.5rem, 4vw, 1.8rem);
  // padding: clamp(1.5rem, 4vw, 2rem);
  // padding: clamp(1rem, 4vw, 2rem);
  border-radius: 25px;
  overflow: hidden;
  position: relative;
  isolation: isolate;
  height: 100%;
  &__header {
    gap: 22px;
    @media (max-width: 575px) {
      gap: 5px;
    }
  }
  &__img {
    width: clamp(70px, 7vw, 89px);
  }

  &--active {
    position: relative;
    isolation: isolate;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border: 3px solid #00a4b6;
      border-radius: inherit;
      z-index: -1;
    }
  }
}

.ts-pricing-card {
  &__title {
    font-size: clamp(1.75rem, 3.7vw, 1.9375rem);
  }
  &__pricing {
    &-currency {
      font-size: clamp(1.1875rem, 2.5vw, 1.25rem);
    }
    &-price {
      font-size: clamp(2.125rem, 3.9vw, 36px);
    }
    &-month {
      font-size: clamp(14px, 2.2vw, 0.9375rem);
    }
  }
  &__subtitle {
    font-size: clamp(1rem, 2vw, 1.125rem);
  }
  &__list {
    font-size: clamp(0.9375rem, 1.5vw, 1rem);
  }
}
.special-offer {
  width: 300px;
}

/* Special offer */

.special-offer .offer-discount {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 100px;
  height: 100px;
  z-index: -1;
}

.special-offer.deg-45 .offer-discount {
  height: 42.3px; /* 100 - 57.5 */
}

.special-offer .offer-discount::before,
.special-offer .offer-discount::before {
  width: 20px; /* 40 x 0,5 */
  height: 5px;
  bottom: -5px;
  left: -37px; /* 40 - 5px */
  transform: skewX(45deg);
}

.special-offer .offer-discount::after {
  width: 5px;
  height: 20px;
  right: -5px;
  top: -37px; /* 40 - 5px */
  transform: skewY(45deg);
  z-index: -1;
}

.special-offer .offer-discount-rotate {
  position: absolute;
  z-index: 1;
  width: 500%;
  left: -200%; /* (width - 100%) / 2 */
  top: 50%;
  height: 40px;
  margin-top: -20px; /* height x -0,5 */
  line-height: 40px; /* same as height, or a little bit more, to compensate font */
  transform: rotate(-45deg);
  font-size: clamp(11px, 3vw, 13px);
  text-align: center;
  background: #00a4b6;
  color: white;
  font-weight: 500;
}

.special-offer.deg-45 .offer-discount-rotate {
  transform: rotate(45deg);
}

.special-offer.deg-45 .offer-discount-rotate strong {
  position: relative;
  right: -14px;
}

.ts-gap-20 {
  gap: 1.25rem;
}
</style>
